
















































































































import Vue, { VueConstructor } from 'vue'
import axios from 'axios'
import { mapState } from 'vuex'

import { IStateRoot } from '../../store/models/IStateRoot'

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT

export default (Vue as VueConstructor<Vue & IStateRoot>).extend({
  name: 'PostReports',
  data() {
    return {
      deleteDialog: false,
      approveDialog: false,
      loadingDelete: false,
      loadingApprove: false,
      selectedReportId: null as number | null,
      notification: false,
      notiText: 'There was an unexpected error.',
      notiColor: 'error',
      headers: [
        { text: 'Title', value: 'post.title' },
        { text: 'Author', value: 'post.author' },
        { text: 'Reported By', value: 'user.username' },
        { text: 'Reasons', value: 'reason' },
        { text: 'Message', value: 'message' },
        { text: 'Actions', value: 'actions' },
      ],
      reports: [],
      options: {},
      totalReports: 0,
      dataLoading: true,
    }
  },
  computed: {
    ...mapState({
      user: (state: any) => state.auth.user,
    }),
  },
  watch: {
    options: {
      handler() {
        this.loadReports()
      },
      deep: true,
    },
  },
  mounted() {
    this.loadReports()
  },
  methods: {
    loadReports() {
      this.dataLoading = true

      axios
        .get(`${API_ENDPOINT}/admin/posts/reports`, {
          params: {
            ...this.options,
          },
          headers: {
            Authorization: `Bearer ${this.user.accessToken.token}`,
          },
        })
        .then((res) => {
          this.dataLoading = false
          this.reports = res.data.data
          this.totalReports = res.data.meta.total
        })
    },
    deletePost(reportId: number) {
      this.deleteDialog = true
      this.selectedReportId = reportId
      console.log(reportId)
    },
    actuallyDeletePost() {
      this.loadingDelete = true

      axios
        .delete(`${API_ENDPOINT}/admin/posts/${this.selectedReportId}`, {
          headers: {
            Authorization: `Bearer ${this.user.accessToken.token}`,
          },
        })
        .then((res) => {
          const { data } = res
          this.notification = true
          this.notiColor = 'success'
          this.notiText = data.success.message
          this.deleteDialog = false
          this.selectedReportId = null
          this.loadingDelete = false
          this.loadReports()
        })
        .catch((res) => {
          const { data } = res
          this.notification = true
          this.notiColor = 'error'
          this.notiText =
            data.success.message || 'There was an unexpected error.'
          this.deleteDialog = false
          this.selectedReportId = null
          this.loadingDelete = false
          this.loadReports()
        })
    },
    approvePost(reportId: number) {
      this.approveDialog = true
      this.selectedReportId = reportId
    },
    actuallyApprovePost() {
      this.loadingApprove = true

      axios
        .post(
          `${API_ENDPOINT}/admin/posts/${this.selectedReportId}/approve`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.user.accessToken.token}`,
            },
          }
        )
        .then((res) => {
          const { data } = res
          this.notification = true
          this.notiColor = 'success'
          this.notiText = data.success.message
          this.approveDialog = false
          this.selectedReportId = null
          this.loadingApprove = false
          this.loadReports()
        })
        .catch((res) => {
          const { data } = res
          this.notification = true
          this.notiColor = 'error'
          this.notiText =
            data.success.message || 'There was an unexpected error.'
          this.deleteDialog = false
          this.selectedReportId = null
          this.loadingDelete = false
          this.loadReports()
        })
    },
  },
})
