var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.reports,"options":_vm.options,"server-items-length":_vm.totalReports,"loading":_vm.dataLoading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.message",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.message ? item.message : 'None')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","href":("https://liketu.com/@" + (item.post.author) + "/" + (item.post.permlink)),"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View Post")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","target":"_blank"},on:{"click":function($event){return _vm.deletePost(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Post")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","target":"_blank"},on:{"click":function($event){return _vm.approvePost(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v("mdi-check-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Approve Post")])])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete this post?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":"red darken-1","loading":_vm.loadingDelete,"disabled":_vm.loadingDelete},on:{"click":_vm.actuallyDeletePost}},[_vm._v(" Yes ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.approveDialog),callback:function ($$v) {_vm.approveDialog=$$v},expression:"approveDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Approve this post?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.approveDialog = false}}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":"green darken-1","loading":_vm.loadingApprove,"disabled":_vm.loadingApprove},on:{"click":_vm.actuallyApprovePost}},[_vm._v(" Yes ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.notiColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.notification = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.notification),callback:function ($$v) {_vm.notification=$$v},expression:"notification"}},[_vm._v(" "+_vm._s(_vm.notiText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }